svg {
  /* initial states */
  //height: 100%;

  .attention,
  .attention1,
  .attention2,
  .attention3,
  .attention4,
  .attention5,
  .pare,
  .pare2,
  .pare3,
  .pare4,
  .seta_baixo,
  .seta_baixo2,
  .seta_baixo3,
  .seta_baixo4,
  .pressao_oleo,
  .temp_agua,
  .nivel_agua,
  .alternador {
    opacity: 0;
  }

  /* animation sequence */
  .engine,
  .fundo_motor,
  .filtros {
    display: inherit;
    //transform-origin: center center;
    animation-name: shake-little;
    animation-duration: 100ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    //animation: hideshow 10s ease infinite;
  }

  .seta-cima-esq {
    fill: #555;
    stroke-opacity: 0;
    transform-box: fill-box;
    transform-origin: center center;
    animation-name: flip;
    animation-duration: 2001ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .seta-cima-dir {
    fill: #555;
    stroke-opacity: 0;
    transform-box: fill-box;
    transform-origin: center center;
    animation-name: flip;
    animation-duration: 1999ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .linha_seta_cima {
    stroke-dasharray: 50;
    animation: dash 5s linear;
    animation-iteration-count: infinite;
  }

  .linha_seta_baixo {
    stroke-dasharray: 50;
    animation: dash 5s linear;
    animation-iteration-count: infinite;
    animation-direction: reverse;
  }
  .attention {
    animation: hideshow 20s ease infinite;
  }
  .temp_agua {
    animation: hideshow 10s ease infinite;
  }

  .red_led_off {
    animation: led_light_anim 10s ease;
  }

  .seta_baixo {
    animation: hideshow 20s ease-in-out infinite;
  }

  @keyframes led_light_anim {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    15% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes led_darken_anim {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
  }

  @keyframes flip {
    0% {
      transform: scaleX(-1);
    }
    50% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(-1);
    }
  }

  @keyframes hideshow {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  /*! * * * * * * * * * * * * * * * * * * * *\
  CSShake :: shake-little
  v1.5.0
  CSS classes to move your DOM
  (c) 2015 @elrumordelaluz
  http://elrumordelaluz.github.io/csshake/
  Licensed under MIT
\* * * * * * * * * * * * * * * * * * * * */
  .shake-little {
    display: inherit;
    transform-origin: center center;
  }

  .shake-freeze,
  .shake-constant.shake-constant--hover:hover,
  .shake-trigger:hover .shake-constant.shake-constant--hover {
    animation-play-state: paused;
  }

  .shake-freeze:hover,
  .shake-trigger:hover .shake-freeze,
  .shake-little:hover,
  .shake-trigger:hover .shake-little {
    animation-play-state: running;
  }

  @keyframes shake-little {
    2% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    4% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    6% {
      transform: translate(0.07%, 0.07%) rotate(0.05deg);
    }
    8% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    10% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    12% {
      transform: translate(0.07%, 0.07%) rotate(0.05deg);
    }
    14% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    16% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    18% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    20% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    22% {
      transform: translate(0.07%, 0.07%) rotate(0.05deg);
    }
    24% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    26% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    28% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    30% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    32% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    34% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    36% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    38% {
      transform: translate(0.07%, 0.07%) rotate(0.05deg);
    }
    40% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    42% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    44% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    46% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    48% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    50% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    52% {
      transform: translate(0.07%, 0.07%) rotate(0.05deg);
    }
    54% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    56% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    58% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    60% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    62% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    64% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    66% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    68% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    70% {
      transform: translate(0.07%, 0.07%) rotate(0.05deg);
    }
    72% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    74% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    76% {
      transform: translate(0px, 0.07%) rotate(0.05deg);
    }
    78% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    80% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    82% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    84% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    86% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    88% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    90% {
      transform: translate(0.07%, 0.07%) rotate(0.05deg);
    }
    92% {
      transform: translate(0.07%, 0.07%) rotate(0.05deg);
    }
    94% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    96% {
      transform: translate(0.07%, 0px) rotate(0.05deg);
    }
    98% {
      transform: translate(0px, 0px) rotate(0.05deg);
    }
    0%,
    100% {
      transform: translate(0, 0) rotate(0);
    }
  }

  .shake-little:hover,
  .shake-trigger:hover .shake-little,
  .shake-little.shake-freeze,
  .shake-little.shake-constant {
    animation-name: shake-little;
    animation-duration: 100ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}

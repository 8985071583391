nav {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  background: white;
  height: 52px;

  #cont {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    background-color: #ffffff;
    @media (max-width: 768px) {
      max-width: 90%;
    }
    @media (max-width: 440px) {
      max-width: 90%;
    }
    /* box-shadow: 2px 2px 4px #1f1f1f; */
    img {
      padding-top: 4px;
      padding-left: 10px;
      padding-bottom: 2px;
      height: 50px;
      @media (max-width: 768px) {
        padding-top: 4px;
        padding-left: 5px;
        padding-bottom: 0px;
        height: 40px;
      }
      @media (max-width: 440px) {
        padding-top: 4px;
        padding-left: 5px;
        padding-bottom: 0px;
        height: 40px;
      }
    }
    ul {
      list-style: none;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    li {
      text-align: right;
      padding: 5px 5px 2px 5px;

      & + li {
        border-left: 1px solid #999;
      }

      a {
        font-family: "Yanone Kaffeesatz", "Noto Mono", sans-serif;
        font-weight: 400;
        font-size: 22px;
        color: #333;
        text-decoration: none;
        padding: 7px 5px 5px 5px;
        @media (max-width: 768px) {
          font-size: 20px;
          padding: 5px 5px 5px 5px;
        }
      }
      a:not(.active_option) {
        color: #333;
      }

      .active_option {
        border-radius: 4px;
        background: #f07c00;
        color: #fff;
        border-bottom: 3px solid #333;
      }
      a:hover {
        border-radius: 4px;
        background: #333;
        color: #fff;
        border-bottom: 3px solid #f07c00;
      }
    }
  }
}

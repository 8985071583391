form {
  background: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;

  #info {
    address {
      line-height: 1;
      margin: 0px;
      padding-bottom: 10px;
      p {
        height: 10px;
      }
    }
  }
  #message {
    line-height: 1.2;
    padding-bottom: 15px;
  }

  h1 {
    font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow', sans-serif;
    font-weight: 400;
    font-size: 28px;
    color: #333;
    padding-bottom: 10px;
  }
  h2 {
    font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow', sans-serif;
    font-weight: 400;
    font-size: 22px;
    color: #333;
  }

  label {
    text-align: left;
    width: 100%;
    font-size: 12px;
    line-height: 1.5;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 15px;
  }

  strong {
    color: #f07c00;
  }

  input,
  .description {
    width: 60%;
    font-family: inherit;
    border: 1px solid #888;
    background: #fff;
    padding: 10px 15px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-size: 14px;
  }

  #mensagem {
    padding: 10px 10px;
  }

  .description {
    height: 140px;
    padding: 8px 7px 10px 7px;
    text-align: justify;
    background: inherit;
  }
  @media (max-width: 700px) {
    width: 100%;
    input,
    .description {
      width: 100%;
    }
  }
}

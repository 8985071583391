#quality {
  width: 29%;
  color: #000;
  background: #fff;
  border-radius: 4px;
  /*   border: 1px solid #aaa; */
  padding: 10px;
  margin: 10px;
  box-shadow: 2px 2px 2px #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  #word {
    width: 90px;
    height: 90px;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #abstract {
      position: absolute;
      width: 80px;
      height: 80px;
      opacity: 0.7;
    }

    #text {
      position: absolute;
      font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow', sans-serif;
      font-weight: 500;
      font-size: 26px;
      z-index: 10;
    }
  }
  #desc {
    margin: 20px 5px 5px 5px;
    font-size: 14px;

    text-align: center;
  }
}

@media (max-width: 700px) {
  #quality {
    width: 50%;
    #word {
      #text {
        font-size: 22px;
      }
    }
    #desc {
      font-size: 12px;
    }
  }
}

@media (max-width: 400px) {
  #quality {
    width: 50%;
    #word {
      #abstract {
        width: 60px;
        height: 60px;
      }
      #text {
        font-size: 16px;
      }
    }
    #desc {
      font-size: 10px;

      text-align: center;
    }
  }
}

.partners {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  figure {
    text-align: center;
    padding: 10px;
    width: 40%;
    img {
      border-radius: 6px;
      height: 150px;
      margin: 10px;
    }
    figcaption {
      text-align: center;

      h3 {
        font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow',
          sans-serif;
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;
        margin: 10px;
        text-align: center;
      }

      h4 {
        font-family: 'Yanone Kaffeesatz', 'Noto Mono', 'Arial Narrow',
          sans-serif;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .partners {
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    figure {
      width: 90%;
      height: auto;
      margin: auto;
      figcaption {
        width: 100%;
        margin: auto;
      }
    }
  }
}

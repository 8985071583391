// @import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
} */
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
/* ------------- */
html,
body {
  //overflow-x: hidden;
}
body {
  position: relative;
  min-width: 320px;
}

#root {
  display: block;
  height: 100%;
  width: 100%;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  overflow-y: scroll;
  //overflow: auto;
  //-ms-overflow-style: none; /* IE and Edge */
  //scrollbar-width: none; /* Firefox */

  -webkit-font-smoothing: antialiased !important; //TODO: fix
}
/*
@-moz-document url-prefix() {
  html,
  body {
    scrollbar-width: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */ /*
::-webkit-scrollbar {
  display: none;
  width: 0px; /* remove scrollbar space */ /*
  background: transparent; /* optional: just make scrollbar invisible */
/*}*/

main {
  width: 100%;
  height: 100%;
  //margin-top: 54px;
  background: #fff;
  //margin-bottom: 48px;
  display: block;

  //border: 1px solid green;
}

body,
input,
button {
  color: #222;
  background: #333;
  font-size: 14px;
  font-family: "Open Sans", "Arial", sans-serif;
}

button {
  cursor: pointer;
}

.content {
  flex: 1;
  margin-bottom: 40px;
  scrollbar-width: none;
  //border: 1px solid red;
}

.grecaptcha-badge,
grecaptcha-logo,
grecaptcha-error {
  margin-bottom: 38px;
}

os-theme-thin-dark .os-theme-thin-dark > .os-scrollbar-horizontal {
  right: 14px;
  height: 14px;
  padding: 0px 6px;
}
.os-theme-thin-dark > .os-scrollbar-vertical {
  bottom: 14px;
  width: 14px;
  padding: 6px 0px;
}

.os-theme-thin-dark.os-host-rtl > .os-scrollbar-horizontal {
  left: 14px;
  right: 0;
}
.os-theme-thin-dark > .os-scrollbar-corner {
  height: 14px;
  width: 14px;
  background-color: transparent;
}
.os-theme-thin-dark > .os-scrollbar > .os-scrollbar-track {
  background: transparent;
}
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track:before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
}
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track:before,
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before {
  left: 0;
  right: 0;
  height: 2px;
  top: 50%;
  margin-top: -1px;
}
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track:before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
  top: 0;
  bottom: 0;
  width: 2px;
  left: 50%;
  margin-left: -1px;
}
.os-theme-thin-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(240, 124, 0, 0.5);
  border-radius: 10px;
}
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover:before,
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active:before {
  height: 4px;
  margin-top: -2px;
}
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover:before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active:before {
  width: 4px;
  margin-left: -2px;
}
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover:before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover:before {
  background: rgba(240, 124, 0, 0.7);
}
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active:before,
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active:before {
  background: rgba(240, 124, 0, 1);
}
.os-theme-thin-dark > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  height: 20%;
  min-width: 30px;
}
.os-theme-thin-dark > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  width: 100%;
  min-height: 30px;
  max-height: 30px;
}
.os-theme-thin-dark.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before {
  transition:
    height 0.3s,
    margin-top 0.3s,
    background 0.2s;
}
.os-theme-thin-dark.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
  transition:
    width 0.3s,
    margin-left 0.3s,
    background 0.2s;
}

.os-scrollbar-pos {
  top: 54px;
  bottom: 40px;
}

#main {
  padding-top: 54px;
}
